<template>
  <v-container>
    <v-row no-gutters align="center">
      <v-col cols="4">
        <h2>Kontakter</h2>
      </v-col>
      <v-col cols="4">
        <v-text-field outlined hide-details dense v-model="search" label="Søg" class="ma-4 white" />
      </v-col>
      <v-col cols="4" align="right">
        <v-btn depressed large @click="addContact">
          <v-icon left>mdi-account-plus-outline</v-icon>
          Tilføj Kontakt
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table :items="accountContacts" :search="search" :headers="headers" :items-per-page="5" sort-desc dense>
      <template #[`item.emailAddresses`]="{ item }">
        {{ $util.FirstEmailInArr(item.emailAddresses) }}
      </template>
      <template #[`item.personalNotes`]="{ item }">
        {{ $util.ShowGroups(item.personalNotes) }}
      </template>
      <template #[`item.action`]="{ item }">
        <div class="text-right">
          <v-btn icon title="Edit" @click="EditContact(item)">
            <v-icon>mdi-account-edit</v-icon>
          </v-btn>
          <v-btn icon title="Edit" @click="DeleteContact(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <CreateEditContact ref="CreateEditContactRef" @contact-updated="bubbleEmit" />
  </v-container>
</template>

<script>
import graphService from '@/services/graphService';
import { mapGetters } from 'vuex';
import CreateEditContact from '@/components/Dialogs/CreateEditContact.vue';
export default {
  components: {
    CreateEditContact,
  },
  data: () => ({
    search: null,
    render: 0,
    headers: [
      {
        text: 'Fornavn',
        value: 'givenName',
      },
      {
        text: 'Efternavn',
        value: 'surname',
      },
      {
        text: 'Hele navn',
        value: 'displayName',
      },
      {
        text: 'Email',
        value: 'emailAddresses',
      },
      {
        text: 'Virksomhed',
        value: 'companyName',
      },
      {
        text: 'Grupper',
        value: 'personalNotes',
      },
      {
        text: '',
        value: 'action',
      },
    ],
  }),
  computed: {
    ...mapGetters(['azureAccount', 'accountContacts']),
  },
  watch: {
    accountContacts() {},
  },
  methods: {
    addContact() {
      this.$refs.CreateEditContactRef.createContact();
    },
    EditContact(user) {
      this.$refs.CreateEditContactRef.editContact(user);
    },
    async DeleteContact(user) {
      await graphService.deleteContact(this.azureAccount, user.id);
      this.$store.dispatch('LOAD_GROUPS_AND_CONTACTS', this.azureAccount);
    },
    bubbleEmit() {
      this.$store.dispatch('LOAD_GROUPS_AND_CONTACTS', this.azureAccount);
    },
  },
};
</script>
