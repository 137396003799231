<template>
  <v-dialog v-model="show" max-width="650" persistent>
    <v-card v-if="currentContact">
      <v-row no-gutters>
        <v-card-title>{{ isEditing ? 'Edit kontakt' : 'Lav kontakt' }}</v-card-title>
        <v-btn depressed v-if="$vuetify.breakpoint.mobile" class="ml-auto mr-4 mt-4" icon @click="show = false">
          <v-icon dark>mdi-close-thick</v-icon>
        </v-btn>
      </v-row>
      <v-divider />
      <v-card-text class="mt-4">
        <v-form v-model="isValid" ref="CreateEditContactRef">
          <v-row class="mt-4">
            <v-col cols="12" md="6">
              <v-text-field v-model="currentContact.givenName" :rules="[rules.required]" dense label="Fornavn"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="currentContact.surname" :rules="[rules.required]" dense label="Efternavn"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="email" :rules="[rules.required]" dense label="Email"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="phoneNumber" dense label="Telefon nummer"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="currentContact.companyName" dense label="Virksomhed"></v-text-field>
            </v-col>
            <v-col cols="12" class="d-flex">
              <v-checkbox class="mr-4" hide-details v-for="group in mergeGroups" :key="group" v-model="groups" :label="group" :value="group"> </v-checkbox>
            </v-col>
            <v-col cols="8">
              <v-text-field dense v-model="newGroup" label="Ny gruppe"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-btn :disabled="!newGroup" @click="addNewGroup">Tilføj gruppe</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-block d-md-flex">
        <v-btn depressed :block="$vuetify.breakpoint.mobile" @click="closeDialog" class="my-4">Annuller</v-btn>
        <v-btn
          depressed
          :block="$vuetify.breakpoint.mobile"
          @click="saveCurrentContact"
          color="success"
          class="ml-0 ml-md-4 my-4"
          :loading="isSaving"
          :disabled="!isValid"
        >
          <v-icon left>mdi-check</v-icon>
          {{ isEditing ? 'Rediger kontakt' : 'Lav kontakt' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import graphService from '@/services/graphService';
export default {
  name: 'CreateEditCompany',
  data: () => ({
    show: false,
    isValid: false,
    isSaving: false,
    currentContact: null,
    email: null,
    phoneNumber: null,
    newGroup: null,
    rules: {
      required: (value) => !!value || 'Felt skal have data.',
    },
  }),
  computed: {
    ...mapGetters(['azureAccount', 'accountGroups']),
    isEditing() {
      return this.currentContact?.id ? true : false;
    },
    mergeGroups() {
      const groups = this.accountGroups.map((x) => x.name);
      return groups.concat(this.groups.filter((x) => !groups.includes(x)));
    },
    groups: {
      get() {
        return this.currentContact?.personalNotes?.split(',') ?? [];
      },
      set(val) {
        this.currentContact.personalNotes = val.filter((x) => x).join(',');
      },
    },
  },
  methods: {
    async saveCurrentContact() {
      this.isSaving = true;
      if (this.isEditing) {
        if (this.currentContact.emailAddresses.length > 0) {
          this.currentContact.emailAddresses[0].address = this.email;
        } else {
          this.currentContact.emailAddresses.push({
            address: this.email,
          });
        }
        if (this.currentContact.phones.length > 0) {
          this.currentContact.phones[0].number = this.phoneNumber;
        } else {
          this.currentContact.phones.push({
            number: this.phoneNumber,
          });
        }
        this.currentContact.displayName = this.currentContact.givenName + ' ' + this.currentContact.surname;
        await graphService.editContact(this.azureAccount, this.currentContact.id, this.currentContact);
      } else {
        this.currentContact.emailAddresses.push({
          address: this.email,
        });
        if (this.phoneNumber) {
          this.currentContact.phones.push({
            number: this.phoneNumber,
          });
        }

        await graphService.postContact(this.azureAccount, this.currentContact);
      }

      this.show = false;
      this.isSaving = false;
      this.email = null;
      this.phoneNumber = null;
      this.$emit('contact-updated');
    },
    editContact(Contact) {
      this.currentContact = _.cloneDeep(Contact);
      if (this.currentContact.emailAddresses.length > 0) {
        this.email = this.currentContact.emailAddresses[0].address;
      }
      if (this.currentContact.phones.length > 0) {
        this.phoneNumber = this.currentContact.phones[0].number;
      }
      this.show = true;
    },
    createContact() {
      this.currentContact = {
        givenName: null,
        displayName: null,
        surname: null,
        companyName: null,
        emailAddresses: [],
        phones: [],
        personalNotes: null,
      };
      this.show = true;
    },
    addNewGroup() {
      this.groups = [...this.groups, this.newGroup];
      this.newGroup = null;
    },
    closeDialog() {
      this.email = null;
      this.phoneNumber = null;
      this.show = false;
    },
  },
};
</script>